a {
  text-decoration: none;
}

a.selected:link,
a.selected:visited,
a.selected:hover,
a.selected:active {
  color: black;
}

/* unvisited link */
a:link {
  color: rgb(99, 99, 99);
}

/* visited link */
a:visited {
  color: rgb(99, 99, 99);
}

/* mouse over link */
a:hover {
  color: rgb(65, 65, 65);
}

/* selected link */
a:active {
  color: black;
}

a:active:focus {
  color: rgb(99, 99, 99);
}

h1,
h2,
h3 {
  font-family: "avenir-heavy", sans-serif;
  font-weight: 580;
}

.logo img {
  width: 80%;
  max-width: 180px;
  margin-bottom: 1em;
}

nav ul {
  list-style-type: none;
  padding: 0px;
  margin: 0px;
  line-height: 2em;
}

.desktop-layout {
  display: grid;
  grid-template-columns: max-content auto;
  margin-top: 2em;
  margin-bottom: 2em;
  margin-left: 6em;
  margin-right: 6em;
  grid-column-gap: 6em;
  grid-row-gap: 2em;
}

.desktop-layout .sidebar {
  height: 80vh;
}

.desktop-layout .footer {
  grid-column: 2 / 3;
}

.mobile-layout .sticky-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  padding-top: 1em;
}

.mobile-layout .logo img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* nav button management (the one at the top right corner) */
.mobile-layout #nav-button {
  position: fixed;
  right: 1.5rem;
  top: 4.5rem;
  z-index: 10000;
}

.mobile-layout .content {
  margin-bottom: 2em;
  margin-left: 1em;
  margin-right: 1em;
}

.mobile-layout .footer {
  margin: 0 1em;
}

@keyframes mobile-nav-in {
  from {
    left: 100vw;
  }
  to {
    left: 40vw;
  }
}

@keyframes mobile-nav-out {
  from {
    left: 40vw;
    opacity: 1;
  }
  to {
    left: 100vw;
  }
}

.mobile-layout .nav {
  position: fixed;
  z-index: 100;
  background-color: white;
  height: 100vh;
  width: 90vw;
  top: 0;
  opacity: 0;
  padding: 2em;
  left: 100vw;
}

.mobile-layout .nav.active {
  left: 40vw;
  opacity: 1;

  animation: mobile-nav-in 0.5s ease;
}

.mobile-layout .nav.inactive {
  left: 100vw;
  opacity: 0;

  animation: mobile-nav-out 0.5s ease;
}

.mobile-layout .nav nav {
  padding-top: 8em;
}

/* Curtain management */
.mobile-layout .curtain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: -1;
  background-color: black;
  opacity: 0;
}

@keyframes curtain-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes curtain-out {
  from {
    opacity: 0.5;
    z-index: 100;
  }
  to {
    opacity: 0;
    z-index: 100;
  }
}

.mobile-layout .curtain.show {
  opacity: 0.5;
  z-index: 100;

  animation: curtain-in 0.5s ease;
}

.mobile-layout .curtain.hide {
  opacity: 0;
  z-index: -1;

  animation: curtain-out 0.5s ease;
}

.footer {
  padding-bottom: 1em;
}

.about-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
}

.about-page div {
  width: 80%;
  max-width: 500px;
}

.gallery {
  display: grid;
  grid-gap: 1em;
}

.gallery.size-xs {
  grid-template-columns: repeat(1, 1fr);
}

.gallery.size-sm {
  grid-template-columns: repeat(1, 1fr);
}

.gallery.size-md {
  grid-template-columns: repeat(1, 1fr);
}

.gallery.size-lg {
  grid-template-columns: repeat(3, 1fr);
}

.gallery.size-xl {
  grid-template-columns: repeat(3, 1fr);
}

.gallery-item {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.gallery-item::before {
  content: "";
  display: block;
  width: 0px;
  height: 0px;
  padding-bottom: 75%;
}

.gallery-item .background {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background-position: center;
  background-size: cover;
  background-blend-mode: multiply;

  transition: transform 0.5s ease, background-color 0.5s ease;
}

.gallery-item.desktop:hover .background,
.gallery-item.mobile.hover .background {
  transform: scale(1.05);
  background-color: rgba(0, 0, 0, 0.4);
}

.gallery-item .title {
  position: absolute;
  bottom: 1em;
  margin-left: 1em;
  margin-right: 1em;
  text-align: center;
  color: white;

  opacity: 0;

  transition: opacity 0.5s ease;
  font-weight: 600;
}

.gallery-item.desktop:hover .title,
.gallery-item.mobile.hover .title {
  opacity: 1;
}

.hamclose {
  --side: 1.5rem; /* unit height in size */
  --unit-height: 9;
  --unit-width: 12;

  --transition-duration: 0.25s;
  position: relative;
  height: var(--side);
  width: calc(var(--side) * var(--unit-width) / var(--unit-height));
}

.hamclose .bar1,
.hamclose .bar2,
.hamclose .bar3 {
  position: absolute;
  background-color: black;
  width: var(--side);
  height: calc(var(--side) / var(--unit-height));
}

/*scale 1.8182*/
.hamclose .bar1 {
  left: 0;
  top: 0;

  transition: transform var(--transition-duration) ease;
}

.hamclose .bar1.state-2 {
  transform: translateY(calc((var(--unit-height) - 1) / 2 * 100%))
    rotate(-45deg);
}

.hamclose .bar2 {
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;

  transition: transform var(--transition-duration) ease,
    opacity var(--transition-duration) ease;
}

.hamclose .bar2.state-2 {
  transform: translateX(
    calc((var(--unit-width) - var(--unit-height)) / var(--unit-height) * -100%)
  );
  opacity: 0;
}

.hamclose .bar3 {
  left: 0;
  bottom: 0;

  transition: transform var(--transition-duration) ease;
}

.hamclose .bar3.state-2 {
  transform: translateY(calc((var(--unit-height) - 1) / 2 * -100%))
    rotate(45deg);
}

.portfolio-item img {
  max-width: 100%;
}
